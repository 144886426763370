<script>
import appConfig from "@/app.config";

/**
 * 404 Error basic component
 */
export default {
    page: {
        title: "404 Error",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
}
</script>

<template>
    <div class="authentication-bg min-vh-100">
        <div class="bg-overlay bg-white"></div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-center py-5">
<!--                        <h1 class="display-1 fw-normal error-text">4<img src="@/assets/images/logo-sm.png" alt=""
                                class="avatar-lg h-auto mx-2">4</h1>-->
                      <img :src="require(`@/assets/mcimages/CI_doha-2.png`)" style="height: 100px; padding-bottom: 30px;"/>
                        <h4 class="text-uppercase text-muted">페이지를 찾을 수 없어요</h4>
                        <div class="mt-5 text-center">
                            <a class="btn btn-primary" @click="$router.go(-1)">되돌아가기</a>
                        </div>
                    </div>
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end authentication section -->
</template>